import React, { useState } from "react";
import processPassphrase from "../../services/passphrase/ProcessPassphrase";
import {PASSPHRASE_FROM_URL_QUERY_PARAMETERS} from "../../Constants";

const handleOnChangePassphrase = (event, passphrase, setSnpInterpretationsDict, setPassphraseErrorMessage) => {
    event.preventDefault();
    event.stopPropagation();
    return processPassphrase(passphrase, setSnpInterpretationsDict, setPassphraseErrorMessage);
}

const PassphraseForm = ({setSnpInterpretationsDict}) => {
    const [passphrase, setPassphrase] = useState(PASSPHRASE_FROM_URL_QUERY_PARAMETERS ? decodeURIComponent(PASSPHRASE_FROM_URL_QUERY_PARAMETERS).trim() : "");
    const [passphraseErrorMessage, setPassphraseErrorMessage] = useState("");
    return <div>
        <h2>{passphraseErrorMessage}</h2>
        <form onSubmit={(event) => handleOnChangePassphrase(event, passphrase, setSnpInterpretationsDict, setPassphraseErrorMessage)}>
            <label>To access SNP interpretations, please enter the passphrase provided by your practitioner:<br/><input
                name={"passPhraseInput"}
                placeholder={"Enter passphrase here"}
                type={"text"}
                id={"passphraseInput"}
                value={passphrase}
                maxLength={255}
                minLength={20}
                size={70}
                onChange={(e) => {
                    setPassphraseErrorMessage("");
                    setPassphrase(e.target.value);
                }}
            /></label>
            <button
                type={"submit"}>submit
            </button>
            <p/>
        </form>
    </div>
}

export default PassphraseForm